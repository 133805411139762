import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import dayjs from 'dayjs';

const PoolishCalculator = () => {
    const [portions, setPortions] = useState(2);
    const [portionSize, setPortionSize] = useState(260);
    const [hydration, setHydration] = useState(70);
    const [plannedDate, setPlannedDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [plannedTime, setPlannedTime] = useState(dayjs().add(25, 'hour').format('HH:mm'));

    const [totalDoughWeight, setTotalDoughWeight] = useState(0);
    const [flourWeight, setFlourWeight] = useState(0);
    const [waterWeight, setWaterWeight] = useState(0);
    const [saltWeight, setSaltWeight] = useState(0);

    const [poolishFlour, setPoolishFlour] = useState(100);
    const [poolishWater, setPoolishWater] = useState(100);
    const [poolishYeast, setPoolishYeast] = useState(3);
    const [poolishHoney, setPoolishHoney] = useState(6);

    const [remainingFlour, setRemainingFlour] = useState(0);
    const [remainingWater, setRemainingWater] = useState(0);
    const [remainingSalt, setRemainingSalt] = useState(0);

    const [step2Time, setStep2Time] = useState('');
    const [startTime, setStartTime] = useState('');

    const dateRef = useRef(null);
    const timeRef = useRef(null);

    useEffect(() => {
        { /*flatpickr(dateRef.current, {
            minDate: "today",
            dateFormat: "Y-m-d"
        });

        flatpickr(timeRef.current, {
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: true
        }); */ }
        calculateDough();
    }, [portions, portionSize, hydration, plannedDate, plannedTime]);

    const calculateDough = () => {
        const totalDoughWeight = Math.round(portions * portionSize);
        const flourWeight = Math.round(totalDoughWeight / ((hydration / 100) + (3 / 100) + 1));
        const waterWeight = Math.round((hydration / 100) * flourWeight);
        const saltWeight = Math.round(0.03 * flourWeight);

        const eatDateTime = dayjs(`${plannedDate} ${plannedTime}`);
        const step2StartTime = eatDateTime.subtract(2, 'hour').format("ddd, DD-MMM-YYYY HH:mm");
        const earliestStartTime = eatDateTime.subtract(25 + 3, 'hour').format("ddd, DD-MMM-YYYY HH:mm");

        setTotalDoughWeight(totalDoughWeight);
        setFlourWeight(flourWeight);
        setWaterWeight(waterWeight);
        setSaltWeight(saltWeight);
        setStep2Time(step2StartTime);
        setStartTime(earliestStartTime);

        if (waterWeight < 401) {
            setPoolishFlour(100);
            setPoolishWater(100);
            setPoolishYeast(3);
            setPoolishHoney(2);
        } else if (waterWeight > 400 && waterWeight < 2501) {
            setPoolishFlour(300);
            setPoolishWater(300);
            setPoolishYeast(6);
            setPoolishHoney(6);
        }

        setRemainingFlour(flourWeight - poolishFlour);
        setRemainingWater(waterWeight - poolishWater);
        setRemainingSalt(saltWeight);
    };

    return (
        <div className="container">
            <h2>Vito Iacopelli Pizza Dough ( 24 hours and Double Fermented )</h2>
            { /* <div className="row g-3 align-items-center timeModule">
                <div className="col-auto">
                    <div className="input-group mb-3">
                        <span className="input-group-text">Planned Date to Eat</span>
                        <input type="text" ref={dateRef} className="form-control" value={plannedDate} onChange={e => setPlannedDate(e.target.value)} />
                        <span className="input-group-text"><i className="bi bi-calendar-week"></i></span>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="input-group mb-3">
                        <span className="input-group-text">Time</span>
                        <input type="text" ref={timeRef}  className="form-control" value={plannedTime} onChange={e => setPlannedTime(e.target.value)} />
                        <span className="input-group-text"><i className="bi bi-clock"></i></span>
                    </div>
                </div>
            </div> */ }

            <div className="row g-3 align-items-center">
                <div className="col-auto">
                    <div className="input-group mb-3">
                        <span className="input-group-text">Portions</span>
                        <input type="text" className="form-control" value={portions} onChange={e => setPortions(e.target.value)} />
                        <span className="input-group-text">number of balls</span>
                    </div>
                </div>

                <div className="col-auto">
                    <div className="input-group mb-3">
                        <span className="input-group-text">Portion Size</span>
                        <input type="text" className="form-control" value={portionSize} onChange={e => setPortionSize(e.target.value)} />
                        <span className="input-group-text">g</span>
                    </div>
                </div>

                <div className="col-auto">
                    <div className="input-group mb-3">
                        <span className="input-group-text">Hydration</span>
                        <input type="text" className="form-control" value={hydration} onChange={e => setHydration(e.target.value)} />
                        <span className="input-group-text">%</span>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="alert alert-warning" style={{ display: portions > 12 ? 'block' : 'none' }} role="alert">
                        The poolish amount of 300:300 is ideal for portions up to 12. For more portions, I recommend splitting the recipe in 2 or more!
                    </div>
                </div>
            </div>

            <div className="row">
                <h2>Final Result:</h2>
                <div className="col-auto">
                    <form className="form-floating font-monospace">
                        <input type="text" className="form-control" value={totalDoughWeight} readOnly />
                        <label>Total Dough Weight (g)</label>
                    </form>
                </div>
                <div className="col-auto">
                    <form className="form-floating font-monospace">
                        <input type="text" className="form-control" value={flourWeight} readOnly />
                        <label>Total Flour Weight (g)</label>
                    </form>
                </div>
                <div className="col-auto">
                    <form className="form-floating font-monospace">
                        <input type="text" className="form-control" value={waterWeight} readOnly />
                        <label>Total Water (g)</label>
                    </form>
                </div>
                <div className="col-auto">
                    <form className="form-floating font-monospace">
                        <input type="text" className="form-control" value={saltWeight} readOnly />
                        <label>Total Salt (g)</label>
                    </form>
                </div>
            </div>

            <div className="row">
                <h2>Step 1 - Make the poolish:</h2>
                { /* <p className="timeModule">{startTime}</p> */ }
                <div className="col-auto">
                    <form className="form-floating font-monospace">
                        <input type="text" className="form-control" value={poolishFlour} readOnly />
                        <label>Flour (g)</label>
                    </form>
                </div>
                <div className="col-auto">
                    <form className="form-floating font-monospace">
                        <input type="text" className="form-control" value={poolishWater} readOnly />
                        <label>Water (g)</label>
                    </form>
                </div>
                <div className="col-auto">
                    <form className="form-floating font-monospace">
                        <input type="text" className="form-control" value={poolishYeast} readOnly />
                        <label>Instant Yeast (g)</label>
                    </form>
                </div>
                <div className="col-auto">
                    <form className="form-floating font-monospace">
                        <input type="text" className="form-control" value={poolishHoney} readOnly />
                        <label>Honey (g)</label>
                    </form>
                </div>

                <div className="form-floating font-monospace">
                    <ul>
                        <li>
                            <input type="checkbox" className="col-auto" />
                            <label>Add Honey to water, mix well and then add Yeast and mix well.</label>
                        </li>
                        <li>
                            <input type="checkbox" />
                            <label>Rest for 15 minutes in Room Temperature until the water starts to bubble.</label>
                        </li>
                        <li>
                            <input type="checkbox" />
                            <label>Make poolish by adding the flour and mixing well.</label>
                        </li> 
                        <li>
                            <input type="checkbox" />
                            <label>Let it sit for 1 hour in the room Temperature.</label>
                        </li>
                        <li>
                            <input type="checkbox" />
                            <label>Refrigerate for 16 - 24 hours.</label>
                        </li>

            </ul>
            </div>

            </div>

            
            <div className="row">
                <h2>Step 2 - Make the dough:</h2>
                { /* <p className="timeModule">{step2Time}</p> */ }
                <div className="col-auto">
                    <form className="form-floating font-monospace">
                        <input type="text" className="form-control" value={remainingFlour} readOnly />
                        <label>Remaining Flour (g)</label>
                    </form>
                </div>
                <div className="col-auto">
                    <form className="form-floating font-monospace">
                        <input type="text" className="form-control" value={remainingWater} readOnly />
                        <label>Remaining Water (g)</label>
                    </form>
                </div>
                <div className="col-auto">
                    <form className="form-floating font-monospace">
                        <input type="text" className="form-control" value={remainingSalt} readOnly />
                        <label>Salt (g)</label>
                    </form>
                </div>

                <div className="form-floating font-monospace">
                    <ul>
                        <li>
                            <input type="checkbox" className="col-auto" />
                            <label>Take Poolish Out of fridge and let it rest for 30 minutes at Room Temperature.</label>
                        </li>
                        <li>
                            <input type="checkbox" />
                            <label>Mix all together and let it rest covered for 30 minutes - 1 hours for autolyse.</label>
                        </li>
                        <li>
                            <input type="checkbox" />
                            <label>Knead Dough and make it a big ball. Dough might be still sticky.</label>
                        </li> 
                        <li>
                            <input type="checkbox" />
                            <label>Cover the Dough Ball and let it Rest for 15 minutes at Room Temperature.</label>
                        </li> 
                        <h3><label>24 hours Pizza</label></h3>
                        <li>
                            <input type="checkbox" />
                            <label>Split by portion Weight and make to smaller balls and put in tray and cover with Cling Wrap. </label>
                        </li>
                        <li>
                            <input type="checkbox" />
                            <label>Rest for 2 hours at room Temperature.</label>
                        </li>
                        <li>
                            <input type="checkbox" />
                            <label>Create Pizza Base and then Bake the Pizza.</label>
                        </li>
                        <li>
                            <input type="checkbox" />
                            <label>Enjoy your Pizza.</label>
                        </li>
                        <h3><label>Double Fermented Pizza</label></h3>
                        <li>
                            <input type="checkbox" />
                            <label>Add Olive oil to the base of dish and put the Pizza Dough. Cover Well.</label>
                        </li>
                        <li>
                            <input type="checkbox" />
                            <label>Refrigerate for 16 - 24 hours. </label>
                        </li>
                        <li>
                            <input type="checkbox" />
                            <label>Double Fermentation Starts Now :-).</label>
                        </li>
                        <li>
                            <input type="checkbox" />
                            <label>Take Pizza Dough Out of fridge and let it rest for 30 minutes - 1 hours at Room Temperature.</label>
                        </li>
                        <li>
                            <input type="checkbox" />
                            <label>Split by portion Weight and make to smaller balls and put in tray and cover with Cling Wrap. </label>
                        </li>
                        <li>
                            <input type="checkbox" />
                            <label>Rest for 2 hours at room Temperature.</label>
                        </li>
                        <li>
                            <input type="checkbox" />
                            <label>Create Pizza Base and then Bake the Pizza.</label>
                        </li>
                        <li>
                            <input type="checkbox" />
                            <label>Enjoy your Pizza.</label>
                        </li>


            </ul>
            </div>
                
            </div>
        </div>
    );
};

export default PoolishCalculator;
